<template>
  <div class="breadcrumbs" :style="`background: ${background};`">
    <div class="container">
      <span
        role="button"
        @click="redirect(item.item)"
        :class="item.item ? '' : 'title-article'"
        v-for="(item, index) in listBreadcrumbs"
        :key="index"
        >{{ item.name }}
        <Icons v-if="item.item" name="arrow-right" color="#616161"
      /></span>
    </div>
  </div>
</template>

<script>
import Icons from '@/components/Icons';
export default {
  components: {
    Icons
  },
  props: {
    background: {
      type: String,
      default: "#fafafa",
    },
  },
  data() {
    return {
      listBreadcrumbs: [],
    };
  },
  methods: {
    redirect(url) {
      try {
        window.location.href = this.$router.resolve(url.replace(process.env.BASE_URL, "")).href
      } catch (e) {
        return;
      }
    },
  },
  jsonld() {
    return [
      {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: this.listBreadcrumbs.map((item) => {
          delete item.slug;
          return item;
        }),
      },
    ];
  },
  created() {
    let path = this.$route.path;
    let breadcrumbs = [];
    breadcrumbs.push({
      "@type": "ListItem",
      position: breadcrumbs.length + 1,
      name: "HOME",
      slug: "/",
      item: `${process.env.BASE_URL}/`,
    });
    path.split("/").map((item, i) => {
      if (item !== "") {
        let url = "";
        if (i === 1) {
          url = `${process.env.BASE_URL}/${item}`;
        } else if (i === 2) {
          url = `${process.env.BASE_URL}/${breadcrumbs[i - 1].slug}/${item}`;
        } else {
          url = "/";
        }

        breadcrumbs.push({
          "@type": "ListItem",
          position: breadcrumbs.length + 1,
          name: item
            .toString()
            .toUpperCase()
            .replace(/[^a-z0-9]/gi, " "),
          slug: item,
          item: url,
        });
      }
    });
    breadcrumbs[breadcrumbs.length - 1] = {
      "@type": "ListItem",
      slug: this.$store.state.seo.pageData.url.replace(
        process.env.BASE_URL,
        ""
      ),
      position: breadcrumbs.length + 1,
      name: this.$store.state.seo.pageData.title,
    };
    this.listBreadcrumbs = breadcrumbs;
  },
};
</script>

<style lang="scss" scoped>
.breadcrumbs {
  overflow: auto;
  padding: 20px 0;
  .container {
    display: flex;
    align-items: center;
    overflow-x: auto;
    @media (max-width: 575.98px) {
      width: fit-content;
      display: -webkit-inline-box;
      overflow-x: auto;
    }
    span {
      overflow: hidden;
      font-size: 14px;
      color: #616161;
      display: flex;
      align-items: center;
      svg {
        width: 18px;
        height: auto;
        margin: 0 10px;
      }
      &.title-article {
        cursor: unset !important;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }
      &:last-child {
        color: #34128c;
        font-weight: 700;
        @media (max-width: 575.98px) {
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          white-space: nowrap;
          display: flex;
          align-items: inherit;
          overflow: hidden;
        }
      }
    }
  }
}
</style>
