var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"navigation-wrapper"},[_c('div',{staticClass:"side-bar-mobile is-mobile container",class:{ enter: _vm.openSearch }},[_c('div',{staticClass:"side-logo-and-close"},[_vm._m(0),_vm._v(" "),_c('Icons',{attrs:{"name":"close-modal-login","color":"#858585"},nativeOn:{"click":function($event){(_vm.openSearch = false), (_vm.showAutocomplete = false)}}})],1),_vm._v(" "),_c('div',{staticClass:"side-content"},[_c('div',{staticClass:"side-search-wrapper"},[_c('form',{attrs:{"action":"/search","method":"get"}},[_c('input',{directives:[{name:"debounce",rawName:"v-debounce:500ms",value:(_vm.onSearch),expression:"onSearch",arg:"500ms"},{name:"model",rawName:"v-model",value:(_vm.searchData),expression:"searchData"}],staticClass:"search",class:{
              active:
                _vm.$store.state.search.autocompleteResult.items.length > 0 &&
                _vm.showAutocomplete,
            },attrs:{"autocomplete":"off","name":"q","placeholder":"Cari di Narasi TV"},domProps:{"value":(_vm.searchData)},on:{"focus":function($event){_vm.showAutocomplete = true},"focusout":function($event){_vm.showAutocomplete = false},"input":function($event){if($event.target.composing)return;_vm.searchData=$event.target.value}}}),_vm._v(" "),_c('Link',{staticClass:"search-icon",class:{ active: _vm.showAutocomplete },attrs:{"to":{
              name: 'search',
              query: {
                q: _vm.searchData ? _vm.searchData : 'mata najwa',
              },
            }}},[_c('Icons',{attrs:{"name":"search","color":"#858585"}})],1),_vm._v(" "),_c('div',{staticClass:"autocomplete-wrapper"},[_c('div',{staticClass:"autocomplete-search",class:{
                show:
                  _vm.showAutocomplete &&
                  _vm.$store.state.search.autocompleteResult.items.length > 0,
              }},[_c('ul',{staticClass:"list-unstyled"},_vm._l((_vm.$store.state.search.autocompleteResult
                    .items),function(item,i){return _c('li',{key:i},[_c('Link',{staticClass:"text",attrs:{"to":{
                      name: item.isText
                        ? 'read-channel-slug'
                        : 'video-channel-slug',
                      params: {
                        channel: item.channel.slug ? item.channel.slug : '',
                        slug: item.slug ? item.slug : '',
                      },
                    }},nativeOn:{"click":function($event){return _vm.redirect()}}},[_c('p',[_vm._v("\n                      "+_vm._s(item.title)+"\n                    ")])])],1)}),0)])])],1),_vm._v(" "),(
            _vm.showAutocomplete &&
            _vm.$store.state.search.autocompleteResult.items.length > 0
          )?_c('div',{staticClass:"shadow-search is-mobile",on:{"click":function($event){_vm.showAutocomplete = false}}}):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"side-login-wrapper"},[(_vm.$store.state.auth.loggedIn && _vm.$store.state.auth.user.picture)?_c('img',{staticClass:"img-user",attrs:{"src":_vm.$store.state.auth.user.picture}}):_c('Icons',{staticClass:"account-icon img-user",attrs:{"name":"account","color":"#0c0b0d"}}),_vm._v(" "),_c('div',{staticClass:"text-login",on:{"click":_vm.checkLogin}},[_vm._v("\n          "+_vm._s(_vm.$store.state.auth.loggedIn ? "SIGN OUT" : "LOGIN/REGISTER")+"\n        ")])],1),_vm._v(" "),_c('div',{staticClass:"side-trending"},[_c('p',{staticClass:"title"},[_vm._v("HIGHLIGHTS")]),_vm._v(" "),_c('div',{staticClass:"list-trending"},_vm._l((_vm.highlights.items),function(trending,index){return _c('a',{key:index,staticClass:"trending-item",attrs:{"href":_vm.checkUrl(trending.url)}},[_vm._v(_vm._s(trending.title)+"\n          ")])}),0)]),_vm._v(" "),_c('div',{staticClass:"side-program"},[_c('div',{staticClass:"btn-wrapper"},_vm._l((_vm.programs.items),function(program,index){return _c('div',{key:`program-${index}`,staticClass:"btn-program",class:{ show: _vm.activeIndexProgram == index },on:{"click":function($event){return _vm.setActive(index)}}},[_vm._v("\n            "+_vm._s(program.title)+"\n            "),_c('Icons',{attrs:{"name":"arrow-triangle","color":_vm.activeIndexProgram == index ? '#34128C' : '#fafafa'}})],1)}),0),_vm._v(" "),_c('div',{staticClass:"child-program",class:{ show: _vm.brandChild.active }},_vm._l((_vm.brandChild.data.channels),function(child,i){return _c('Link',{key:`child-${i}`,staticClass:"child",attrs:{"to":{
              name: 'program-childBrand-slugProgram',
              params: {
                childBrand: _vm.brandChild.data.slug,
                slugProgram: child.slug,
              },
              query: {
                type: 'highlights',
              },
            }}},[_c('div',{staticClass:"img-program"},[_c('ImageResponsive',{attrs:{"imageUrl":child.logo,"fromUrl":true,"widthMobile":"50","heightMobile":"50"}})],1),_vm._v("\n            "+_vm._s(child.title)+"\n          ")])}),1)]),_vm._v(" "),_c('div',{staticClass:"side-khas-narasi"},[_c('p',{staticClass:"title"},[_vm._v("KHAS NARASI")]),_vm._v(" "),_c('div',{staticClass:"list-khas-narasi"},_vm._l((_vm.khasNarasi),function(khas,index){return _c('Link',{key:index,staticClass:"khas-narasi-item",attrs:{"to":{
              name: khas.slug,
              params: {},
            }}},[_vm._v(_vm._s(khas.name)+"\n          ")])}),1)]),_vm._v(" "),_c('div',{staticClass:"side-social-media"},[_c('a',{attrs:{"href":"https://www.instagram.com/narasi.tv","target":"_blank","rel":"noopener"}},[_c('Icons',{attrs:{"name":"logo-instagram-new","color":"#858585"}})],1),_vm._v(" "),_c('a',{attrs:{"href":"https://twitter.com/narasitv","target":"_blank","rel":"noopener"}},[_c('Icons',{attrs:{"name":"logo-x","color":"#858585"}})],1),_vm._v(" "),_c('a',{attrs:{"href":"https://www.facebook.com/narasi.tv","target":"_blank","rel":"noopener"}},[_c('Icons',{attrs:{"name":"logo-facebook","color":"#858585"}})],1),_vm._v(" "),_c('a',{attrs:{"href":"https://www.youtube.com/channel/UCzI8ArgVBHXN3lSz-dI0yRw","aria-label":"Youtube","target":"_blank","rel":"noopener"}},[_c('Icons',{attrs:{"name":"logo-youtube","color2":"#858585","color":"#ffffff"}})],1)])])]),_vm._v(" "),_c('div',{staticClass:"narasi-navbar"},[(_vm.strip)?_c('div',{staticClass:"strip-text"},[_c('div',{staticClass:"container"},[_c('div'),_vm._v(" "),_c('a',{staticClass:"text",attrs:{"href":"https://narasi.tv/playfest?utm_source=website&utm_medium=website","target":"_blank","rel":"noopener"}},[_vm._v("Join Playfest 2022 Now! "),_c('Icons',{attrs:{"name":"arrow-right"}})],1),_vm._v(" "),_c('Icons',{attrs:{"name":"close-modal-login"},nativeOn:{"click":function($event){_vm.$emit('removeStrip'), (_vm.strip = false)}}})],1)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"navbar-top"},[_c('div',{staticClass:"container top-desktop"},[_vm._m(1),_vm._v(" "),_c('ul',{staticClass:"container list-menu"},[_vm._l((_vm.menus_with_hover.items),function(menu,index){return _c('li',{key:'menu-with-hover' + index},[_c('Link',{key:index,staticClass:"menu-item",class:[
                _vm.$route.name == menu.slug ||
                _vm.$route.params.category == menu.slug
                  ? 'Link-exact-active'
                  : '',
                {
                  hover: _vm.hover.status && _vm.hover.slug == menu.slug,
                },
              ],attrs:{"to":{
                name: menu.type == 'interest' ? 'category' : menu.slug,
                params:
                  menu.type == 'interest' ? { category: menu.slug } : {},
                query: {
                  ref: `${_vm.slugify(menu.name)}-navbar`,
                },
              }}},[_vm._v("\n              "+_vm._s(menu.name)+"\n              ")])],1)}),_vm._v(" "),_vm._l((_vm.menus),function(menu,index){return _c('li',{directives:[{name:"show",rawName:"v-show",value:(!_vm.menus_with_hover.loading),expression:"!menus_with_hover.loading"}],key:'menu' + index},[_c('Link',{key:index,staticClass:"menu-item",class:[
                _vm.$route.name.includes(menu.slug) ? 'Link-exact-active' : '',
                { soon: !menu.launch },
              ],attrs:{"to":{
                name: menu.slug,
                params: {},
                query: {
                  ref: `${_vm.slugify(menu.name)}-navbar`,
                },
              }}},[(menu.icon)?_c('Icons',{attrs:{"name":menu.icon}}):_vm._e(),_vm._v(_vm._s(menu.name)),(!menu.launch)?_c('sup',[_vm._v("SOON")]):_vm._e()],1)],1)})],2),_vm._v(" "),_c('div',{staticClass:"search-login-wrapper"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$route.name.includes('auth')),expression:"!$route.name.includes('auth')"}],staticClass:"search-wrapper"},[(!_vm.showSearch)?_c('Icons',{staticClass:"search-icon absolute",attrs:{"name":"search"},nativeOn:{"click":function($event){return _vm.expandSearch.apply(null, arguments)}}}):_vm._e(),_vm._v(" "),_c('form',{class:{ show: _vm.showSearch },attrs:{"action":"/search","method":"get"}},[_c('input',{directives:[{name:"debounce",rawName:"v-debounce:500ms",value:(_vm.onSearch),expression:"onSearch",arg:"500ms"},{name:"model",rawName:"v-model",value:(_vm.searchData),expression:"searchData"}],ref:"searchInput",staticClass:"search",class:[
                  {
                    active:
                      _vm.$store.state.search.autocompleteResult.items.length >
                        0 && _vm.showAutocomplete,
                  },
                  { disabled: !_vm.showSearch },
                ],attrs:{"type":"search","autocomplete":"off","name":"q","placeholder":"Ketik kata kunci untuk mencari"},domProps:{"value":(_vm.searchData)},on:{"focus":function($event){_vm.showAutocomplete = true},"input":function($event){if($event.target.composing)return;_vm.searchData=$event.target.value}}}),_vm._v(" "),_c('div',{staticClass:"btn-wrapper"},[(_vm.showSearch)?_c('div',{attrs:{"aria-label":"Search","to":{
                    name: 'search',
                    query: {
                      q: _vm.searchData,
                    },
                  }},on:{"click":function($event){return _vm.goSearch()}}},[_c('Icons',{staticClass:"search-icon",attrs:{"name":"search"}})],1):_vm._e(),_vm._v(" "),(_vm.showSearch)?_c('Icons',{staticClass:"search-icon close",attrs:{"name":"close-modal-login","color":"#ffffff"},nativeOn:{"click":function($event){(_vm.showSearch = false), (_vm.showAutocomplete = false)}}}):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"autocomplete-wrapper"},[_c('div',{staticClass:"autocomplete-search",class:{
                  show:
                    _vm.showAutocomplete &&
                    _vm.$store.state.search.autocompleteResult.items.length > 0 &&
                    _vm.showSearch,
                }},[_c('ul',{staticClass:"list-unstyled"},_vm._l((_vm.$store.state.search.autocompleteResult
                      .items),function(item,i){return _c('li',{key:i},[_c('Link',{staticClass:"text",attrs:{"to":{
                        name: item.isText
                          ? 'read-channel-slug'
                          : 'video-channel-slug',
                        params: {
                          channel: item.channel.slug ? item.channel.slug : '',
                          slug: item.slug ? item.slug : '',
                        },
                      }},nativeOn:{"click":function($event){return _vm.redirect()}}},[_c('p',[_vm._v("\n                        "+_vm._s(item.title)+"\n                      ")])])],1)}),0)])])],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showSearch),expression:"!showSearch"}],staticClass:"login-wrapper"},[(_vm.$store.state.auth.loggedIn && _vm.$route.name == 'account')?_c('div',{staticClass:"text-login desktop",on:{"click":function($event){return _vm.$router.push({
                  name: 'auth-login',
                  query: {
                    redirect: _vm.$route.path,
                  },
                })}},nativeOn:{"click":function($event){return _vm.logout.apply(null, arguments)}}},[_vm._v("\n              SIGN OUT\n            ")]):(_vm.$store.state.auth.loggedIn)?_c('Link',{attrs:{"to":{
                name: 'account',
              }}},[_c('div',{staticClass:"profile"},[_c('ProfilePicture',{attrs:{"color":'#FFE900',"fontSize":'13px',"initial":_vm.$store.state.auth.user && _vm.$store.state.auth.user.fullname
                      ? _vm.$store.state.auth.user.fullname.substr(0, 1)
                      : ''}})],1)]):_c('div',{staticClass:"text-login",on:{"click":function($event){return _vm.$router.push({
                  name: 'auth-login',
                  query: {
                    redirect: _vm.$route.path,
                  },
                })}}},[_c('Icons',{attrs:{"name":"account","color":"#858585"}}),_vm._v("LOGIN\n            ")],1)],1)])]),_vm._v(" "),_c('div',{staticClass:"container top-mobile"},[_vm._m(2),_vm._v(" "),_c('div',{staticClass:"search-login-wrapper"},[_c('div',{staticClass:"login-wrapper"},[(_vm.$store.state.auth.loggedIn && _vm.$route.name == 'account')?_c('div',{staticClass:"text-login mobile",on:{"click":function($event){return _vm.$router.push({
                  name: 'auth-login',
                  query: {
                    redirect: _vm.$route.path,
                  },
                })}},nativeOn:{"click":function($event){return _vm.logout.apply(null, arguments)}}},[_vm._v("\n              SIGN OUT\n            ")]):(_vm.$store.state.auth.loggedIn)?_c('Link',{attrs:{"to":{
                name: 'account',
              }}},[_c('div',{staticClass:"profile"},[_c('ProfilePicture',{attrs:{"color":'#FFE900',"fontSize":'13px',"initial":_vm.$store.state.auth.user && _vm.$store.state.auth.user.fullname
                      ? _vm.$store.state.auth.user.fullname.substr(0, 1)
                      : ''}})],1)]):_c('div',{staticClass:"text-login",on:{"click":function($event){return _vm.$router.push({
                  name: 'auth-login',
                  query: {
                    redirect: _vm.$route.path,
                  },
                })}}},[_c('Icons',{staticClass:"account-icon",attrs:{"name":"account","color":"#858585"}}),_vm._v("LOGIN\n            ")],1)],1),_vm._v(" "),_c('div',{staticClass:"search-wrapper"},[_c('div',{on:{"click":function($event){_vm.openSearch = !_vm.openSearch}}},[_c('Icons',{attrs:{"name":"side-bar-icon","color":"#0c0b0d"}})],1)])])])]),_vm._v(" "),(
        !_vm.$route.name.includes('account') &&
        _vm.$route.name !== 'academy-slug' &&
        !_vm.$route.name.includes('search')
      )?_c('div',{staticClass:"navbar-bottom is-mobile"},[_c('div',{staticClass:"container list-menu"},[_vm._l((_vm.menus_with_hover.items),function(menu,index){return [_c('Link',{key:`menus-mobile-with-hover-${index}`,staticClass:"menu-item",class:[
              _vm.$route.name == menu.slug || _vm.$route.params.category == menu.slug
                ? 'Link-exact-active'
                : '',
            ],attrs:{"to":{
              name: menu.type == 'interest' ? 'category' : menu.slug,
              params: menu.type == 'interest' ? { category: menu.slug } : {},
              query: {
                ref: `${_vm.slugify(menu.name)}-navbar`,
              },
            }}},[_vm._v(_vm._s(menu.name)+"\n          ")])]}),_vm._v(" "),_vm._l((_vm.menus),function(menu,index){return [_c('Link',{directives:[{name:"show",rawName:"v-show",value:(!_vm.menus_with_hover.loading),expression:"!menus_with_hover.loading"}],key:`menus-mobile-${index}`,staticClass:"menu-item",class:[
              _vm.$route.name.includes(menu.slug) ? 'Link-exact-active' : '',
              { soon: !menu.launch },
            ],attrs:{"to":{
              name: menu.isCategory ? 'category-slug' : menu.slug,
              params: menu.isCategory
                ? { category: menu.slug, slug: 'semua' }
                : {},
              query: {
                ref: `${_vm.slugify(menu.name)}-navbar`,
              },
            }}},[(menu.icon)?_c('Icons',{attrs:{"name":menu.icon}}):_vm._e(),_vm._v(_vm._s(menu.name)),(!menu.launch)?_c('sup',[_vm._v("SOON")]):_vm._e()],1)]})],2)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"navbar-trending"},[_c('div',{staticClass:"container list-menu"},[_c('div',{staticClass:"slider-trending"},[_c('div',{directives:[{name:"swiper",rawName:"v-swiper:mySwiperTrending",value:(_vm.swiperOptions.trendings),expression:"swiperOptions.trendings",arg:"mySwiperTrending"}],staticClass:"py-2"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.highlights.items),function(trending,index){return _c('a',{key:'trending-' + index,staticClass:"trending-item swiper-slide",attrs:{"href":_vm.checkUrl(trending.url)}},[_vm._v(_vm._s(trending.title))])}),0),_vm._v(" "),_c('div',{staticClass:"swiper-pagination"})]),_vm._v(" "),_c('div',{staticClass:"arrows is-mobile-flex"},[_c('div',{staticClass:"prev-trending"},[_c('Icons',{attrs:{"name":"arrow-left","color":"#0c0b0d"}})],1),_vm._v(" "),_c('div',{staticClass:"next-trending"},[_c('Icons',{attrs:{"name":"arrow-right","color":"#0c0b0d"}})],1)])])])])]),_vm._v(" "),(
      _vm.showAutocomplete &&
      _vm.$store.state.search.autocompleteResult.items.length > 0 &&
      _vm.showSearch
    )?_c('div',{staticClass:"shadow-search is-desktop",on:{"click":function($event){_vm.showAutocomplete = false}}}):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"logo",attrs:{"href":"https://narasi.tv"}},[_c('img',{staticClass:"logo-narasi-two",attrs:{"src":"https://narasi.tv/img/logo-narasi-yp-1.webp","alt":"Narasi"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo-wrapper"},[_c('a',{staticClass:"logo",attrs:{"href":"https://narasi.tv","aria-label":"Narasi"}},[_c('img',{staticClass:"logo-narasi-two",attrs:{"src":"https://narasi.tv/img/logo-narasi-yp-2.webp","alt":"Narasi"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo-wrapper"},[_c('a',{staticClass:"logo",attrs:{"href":"https://narasi.tv"}},[_c('img',{staticClass:"logo-narasi-two",attrs:{"src":"https://narasi.tv/img/logo-narasi-yp-1.webp","alt":"Narasi"}})])])
}]

export { render, staticRenderFns }