<template>
  <div class="d-flex justify-content-start mt-3">
    <div class="border-devider d-inline-block"></div>
    <template v-if="type === 'standart'">
      <h2 class="devider-title fs-20 fw-700 d-inline-block">{{ title }}</h2>
    </template>
    <template v-else>
      <h2 class="devider-title fs-14 fw-700 d-inline-block">{{ title }}</h2>
    </template>
  </div>
</template>

<script>
  export default {
    props: {
      type: {default: "standart"},
      title: {default: null}
    }

  }
</script>
