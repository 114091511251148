<template>
  <div class="navigation-wrapper">
    <div
      class="side-bar-mobile is-mobile container"
      :class="{ enter: openSearch }"
    >
      <div class="side-logo-and-close">
        <a href="https://narasi.tv" class="logo">
          <img
            src="https://narasi.tv/img/logo-narasi-yp-1.webp"
            class="logo-narasi-two"
            alt="Narasi"
          />
        </a>
        <Icons
          name="close-modal-login"
          color="#858585"
          @click.native="(openSearch = false), (showAutocomplete = false)"
        />
      </div>
      <div class="side-content">
        <div class="side-search-wrapper">
          <form action="/search" method="get">
            <input
              @focus="showAutocomplete = true"
              @focusout="showAutocomplete = false"
              autocomplete="off"
              class="search"
              :class="{
                active:
                  $store.state.search.autocompleteResult.items.length > 0 &&
                  showAutocomplete,
              }"
              name="q"
              v-debounce:500ms="onSearch"
              placeholder="Cari di Narasi TV"
              v-model="searchData"
            />
            <Link
              :to="{
                name: 'search',
                query: {
                  q: searchData ? searchData : 'mata najwa',
                },
              }"
              class="search-icon"
              :class="{ active: showAutocomplete }"
            >
              <Icons name="search" color="#858585" />
            </Link>
            <div class="autocomplete-wrapper">
              <div
                class="autocomplete-search"
                :class="{
                  show:
                    showAutocomplete &&
                    $store.state.search.autocompleteResult.items.length > 0,
                }"
              >
                <ul class="list-unstyled">
                  <li
                    v-for="(item, i) in $store.state.search.autocompleteResult
                      .items"
                    :key="i"
                  >
                    <Link
                      class="text"
                      @click.native="redirect()"
                      :to="{
                        name: item.isText
                          ? 'read-channel-slug'
                          : 'video-channel-slug',
                        params: {
                          channel: item.channel.slug ? item.channel.slug : '',
                          slug: item.slug ? item.slug : '',
                        },
                      }"
                    >
                      <p>
                        {{ item.title }}
                      </p>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </form>
          <div
            class="shadow-search is-mobile"
            v-if="
              showAutocomplete &&
              $store.state.search.autocompleteResult.items.length > 0
            "
            @click="showAutocomplete = false"
          ></div>
        </div>
        <div class="side-login-wrapper">
          <img
            v-if="$store.state.auth.loggedIn && $store.state.auth.user.picture"
            class="img-user"
            :src="$store.state.auth.user.picture"
          />
          <Icons
            v-else
            name="account"
            class="account-icon img-user"
            color="#0c0b0d"
          />
          <div class="text-login" @click="checkLogin">
            {{ $store.state.auth.loggedIn ? "SIGN OUT" : "LOGIN/REGISTER" }}
          </div>
        </div>
        <div class="side-trending">
          <p class="title">HIGHLIGHTS</p>
          <div class="list-trending">
            <a
              v-for="(trending, index) in highlights.items"
              :key="index"
              class="trending-item"
              :href="checkUrl(trending.url)"
              >{{ trending.title }}
            </a>
          </div>
        </div>
        <div class="side-program">
          <div class="btn-wrapper">
            <div
              v-for="(program, index) in programs.items"
              :key="`program-${index}`"
              @click="setActive(index)"
              class="btn-program"
              :class="{ show: activeIndexProgram == index }"
            >
              {{ program.title }}
              <Icons
                name="arrow-triangle"
                :color="activeIndexProgram == index ? '#34128C' : '#fafafa'"
              />
            </div>
          </div>
          <div class="child-program" :class="{ show: brandChild.active }">
            <Link
              v-for="(child, i) in brandChild.data.channels"
              :key="`child-${i}`"
              :to="{
                name: 'program-childBrand-slugProgram',
                params: {
                  childBrand: brandChild.data.slug,
                  slugProgram: child.slug,
                },
                query: {
                  type: 'highlights',
                },
              }"
              class="child"
            >
              <div class="img-program">
                <ImageResponsive
                  :imageUrl="child.logo"
                  :fromUrl="true"
                  widthMobile="50"
                  heightMobile="50"
                />
              </div>
              {{ child.title }}
            </Link>
          </div>
        </div>
        <div class="side-khas-narasi">
          <p class="title">KHAS NARASI</p>
          <div class="list-khas-narasi">
            <Link
              v-for="(khas, index) in khasNarasi"
              :key="index"
              class="khas-narasi-item"
              :to="{
                name: khas.slug,
                params: {},
              }"
              >{{ khas.name }}
            </Link>
          </div>
        </div>
        <div class="side-social-media">
          <a
            href="https://www.instagram.com/narasi.tv"
            target="_blank"
            rel="noopener"
            ><Icons name="logo-instagram-new" color="#858585"
          /></a>
          <a href="https://twitter.com/narasitv" target="_blank" rel="noopener"
            ><Icons name="logo-x" color="#858585"
          /></a>
          <a
            href="https://www.facebook.com/narasi.tv"
            target="_blank"
            rel="noopener"
            ><Icons name="logo-facebook" color="#858585"
          /></a>
          <a
            href="https://www.youtube.com/channel/UCzI8ArgVBHXN3lSz-dI0yRw"
            aria-label="Youtube"
            target="_blank"
            rel="noopener"
            ><Icons name="logo-youtube" color2="#858585" color="#ffffff"
          /></a>
        </div>
      </div>
    </div>
    <div class="narasi-navbar">
      <div class="strip-text" v-if="strip">
        <div class="container">
          <div></div>
          <a
            href="https://narasi.tv/playfest?utm_source=website&utm_medium=website"
            target="_blank"
            rel="noopener"
            class="text"
            >Join Playfest 2022 Now! <Icons name="arrow-right"
          /></a>
          <Icons
            name="close-modal-login"
            @click.native="$emit('removeStrip'), (strip = false)"
          />
        </div>
      </div>
      <!-- <BillboardMobile /> -->
      <div class="navbar-top">
        <div class="container top-desktop">
          <div class="logo-wrapper">
            <a href="https://narasi.tv" class="logo" aria-label="Narasi">
              <img
                src="https://narasi.tv/img/logo-narasi-yp-2.webp"
                class="logo-narasi-two"
                alt="Narasi"
              />
              <!-- <Icons name="logo-narasi-two" /> -->
            </a>
          </div>
          <ul class="container list-menu">
            <li
              v-for="(menu, index) in menus_with_hover.items"
              :key="'menu-with-hover' + index"
            >
              <Link
                :key="index"
                class="menu-item"
                :to="{
                  name: menu.type == 'interest' ? 'category' : menu.slug,
                  params:
                    menu.type == 'interest' ? { category: menu.slug } : {},
                  query: {
                    ref: `${slugify(menu.name)}-navbar`,
                  },
                }"
                :class="[
                  $route.name == menu.slug ||
                  $route.params.category == menu.slug
                    ? 'Link-exact-active'
                    : '',
                  {
                    hover: hover.status && hover.slug == menu.slug,
                  },
                ]"
              >
                <!-- <Icons :name="menu.icon" v-if="menu.icon" /> -->
                {{ menu.name }}
                <!-- <sup v-if="!menu.launch">SOON</sup> -->
              </Link>
            </li>
            <li
              v-for="(menu, index) in menus"
              :key="'menu' + index"
              v-show="!menus_with_hover.loading"
            >
              <Link
                :key="index"
                class="menu-item"
                :to="{
                  name: menu.slug,
                  params: {},
                  query: {
                    ref: `${slugify(menu.name)}-navbar`,
                  },
                }"
                :class="[
                  $route.name.includes(menu.slug) ? 'Link-exact-active' : '',
                  { soon: !menu.launch },
                ]"
                ><Icons :name="menu.icon" v-if="menu.icon" />{{ menu.name
                }}<sup v-if="!menu.launch">SOON</sup>
              </Link>
            </li>
          </ul>
          <div class="search-login-wrapper">
            <div class="search-wrapper" v-show="!$route.name.includes('auth')">
              <Icons
                name="search"
                class="search-icon absolute"
                @click.native="expandSearch"
                v-if="!showSearch"
              />
              <form action="/search" method="get" :class="{ show: showSearch }">
                <input
                  ref="searchInput"
                  @focus="showAutocomplete = true"
                  type="search"
                  autocomplete="off"
                  class="search"
                  :class="[
                    {
                      active:
                        $store.state.search.autocompleteResult.items.length >
                          0 && showAutocomplete,
                    },
                    { disabled: !showSearch },
                  ]"
                  name="q"
                  v-debounce:500ms="onSearch"
                  placeholder="Ketik kata kunci untuk mencari"
                  v-model="searchData"
                />
                <div class="btn-wrapper">
                  <div
                    aria-label="Search"
                    :to="{
                      name: 'search',
                      query: {
                        q: searchData,
                      },
                    }"
                    v-if="showSearch"
                    @click="goSearch()"
                  >
                    <Icons name="search" class="search-icon" />
                  </div>
                  <Icons
                    @click.native="
                      (showSearch = false), (showAutocomplete = false)
                    "
                    name="close-modal-login"
                    color="#ffffff"
                    class="search-icon close"
                    v-if="showSearch"
                  />
                </div>
              </form>
              <!-- </Transition> -->
              <div class="autocomplete-wrapper">
                <div
                  class="autocomplete-search"
                  :class="{
                    show:
                      showAutocomplete &&
                      $store.state.search.autocompleteResult.items.length > 0 &&
                      showSearch,
                  }"
                >
                  <ul class="list-unstyled">
                    <li
                      v-for="(item, i) in $store.state.search.autocompleteResult
                        .items"
                      :key="i"
                    >
                      <Link
                        class="text"
                        @click.native="redirect()"
                        :to="{
                          name: item.isText
                            ? 'read-channel-slug'
                            : 'video-channel-slug',
                          params: {
                            channel: item.channel.slug ? item.channel.slug : '',
                            slug: item.slug ? item.slug : '',
                          },
                        }"
                      >
                        <p>
                          {{ item.title }}
                        </p>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- <Transition name="slide-fade" mode="out-in"> -->
            <div class="login-wrapper" v-show="!showSearch">
              <div
                v-if="$store.state.auth.loggedIn && $route.name == 'account'"
                @click="
                  $router.push({
                    name: 'auth-login',
                    query: {
                      redirect: $route.path,
                    },
                  })
                "
                class="text-login desktop"
                @click.native="logout"
              >
                SIGN OUT
              </div>
              <Link
                v-else-if="$store.state.auth.loggedIn"
                :to="{
                  name: 'account',
                }"
              >
                <!-- <Icons name="account" class="account-icon" /> -->
                <div class="profile">
                  <ProfilePicture
                    :color="'#FFE900'"
                    :fontSize="'13px'"
                    :initial="
                      $store.state.auth.user && $store.state.auth.user.fullname
                        ? $store.state.auth.user.fullname.substr(0, 1)
                        : ''
                    "
                  />
                </div>
              </Link>
              <div
                v-else
                @click="
                  $router.push({
                    name: 'auth-login',
                    query: {
                      redirect: $route.path,
                    },
                  })
                "
                class="text-login"
              >
                <Icons name="account" color="#858585" />LOGIN
              </div>
            </div>
            <!-- </Transition> -->
          </div>
        </div>
        <div class="container top-mobile">
          <div class="logo-wrapper">
            <a href="https://narasi.tv" class="logo">
              <img
                src="https://narasi.tv/img/logo-narasi-yp-1.webp"
                class="logo-narasi-two"
                alt="Narasi"
              />
            </a>
          </div>
          <div class="search-login-wrapper">
            <div class="login-wrapper">
              <div
                v-if="$store.state.auth.loggedIn && $route.name == 'account'"
                @click="
                  $router.push({
                    name: 'auth-login',
                    query: {
                      redirect: $route.path,
                    },
                  })
                "
                class="text-login mobile"
                @click.native="logout"
              >
                SIGN OUT
              </div>
              <Link
                v-else-if="$store.state.auth.loggedIn"
                :to="{
                  name: 'account',
                }"
              >
                <div class="profile">
                  <ProfilePicture
                    :color="'#FFE900'"
                    :fontSize="'13px'"
                    :initial="
                      $store.state.auth.user && $store.state.auth.user.fullname
                        ? $store.state.auth.user.fullname.substr(0, 1)
                        : ''
                    "
                  />
                </div>
              </Link>
              <div
                v-else
                @click="
                  $router.push({
                    name: 'auth-login',
                    query: {
                      redirect: $route.path,
                    },
                  })
                "
                class="text-login"
              >
                <Icons
                  name="account"
                  class="account-icon"
                  color="#858585"
                />LOGIN
              </div>
            </div>
            <div class="search-wrapper">
              <div @click="openSearch = !openSearch">
                <Icons name="side-bar-icon" color="#0c0b0d" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="navbar-bottom is-mobile"
        v-if="
          !$route.name.includes('account') &&
          $route.name !== 'academy-slug' &&
          !$route.name.includes('search')
        "
      >
        <div class="container list-menu">
          <template v-for="(menu, index) in menus_with_hover.items">
            <Link
              :key="`menus-mobile-with-hover-${index}`"
              class="menu-item"
              :to="{
                name: menu.type == 'interest' ? 'category' : menu.slug,
                params: menu.type == 'interest' ? { category: menu.slug } : {},
                query: {
                  ref: `${slugify(menu.name)}-navbar`,
                },
              }"
              :class="[
                $route.name == menu.slug || $route.params.category == menu.slug
                  ? 'Link-exact-active'
                  : '',
              ]"
              >{{ menu.name }}
            </Link>
          </template>
          <template v-for="(menu, index) in menus">
            <Link
              v-show="!menus_with_hover.loading"
              :key="`menus-mobile-${index}`"
              class="menu-item"
              :to="{
                name: menu.isCategory ? 'category-slug' : menu.slug,
                params: menu.isCategory
                  ? { category: menu.slug, slug: 'semua' }
                  : {},
                query: {
                  ref: `${slugify(menu.name)}-navbar`,
                },
              }"
              :class="[
                $route.name.includes(menu.slug) ? 'Link-exact-active' : '',
                { soon: !menu.launch },
              ]"
              ><Icons :name="menu.icon" v-if="menu.icon" />{{ menu.name
              }}<sup v-if="!menu.launch">SOON</sup>
            </Link>
          </template>
        </div>
      </div>
      <div class="navbar-trending">
        <div class="container list-menu">
          <div class="slider-trending">
            <div
              class="py-2"
              v-swiper:mySwiperTrending="swiperOptions.trendings"
            >
              <div class="swiper-wrapper">
                <!-- <ShimmerCategory v-if="programs.loading" /> -->
                <a
                  v-for="(trending, index) in highlights.items"
                  :key="'trending-' + index"
                  class="trending-item swiper-slide"
                  :href="checkUrl(trending.url)"
                  >{{ trending.title }}</a
                >
              </div>
              <div class="swiper-pagination"></div>
            </div>
            <div class="arrows is-mobile-flex">
              <div class="prev-trending">
                <Icons name="arrow-left" color="#0c0b0d" />
              </div>
              <div class="next-trending">
                <Icons name="arrow-right" color="#0c0b0d" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="shadow-search is-desktop"
      v-if="
        showAutocomplete &&
        $store.state.search.autocompleteResult.items.length > 0 &&
        showSearch
      "
      @click="showAutocomplete = false"
    ></div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import Loading from "../components/Loading";
import CardOne from "../components/cards/CardOne.vue";
import CardNavbar from "../components/cards/CardNavbar.vue";
import { mapGetters, mapState } from "vuex";
import BillboardMobile from "../components/ads/homepage/BillboardMobile.vue";
import Link from "@/components/Link";
import Icons from "@/components/Icons";
import ImageResponsive from "@/components/ImageResponsive";
import Shimmer from "@/components/Shimmer";
import ProfilePicture from "@/components/ProfilePicture";

export default {
  components: {
    ProfilePicture,
    Shimmer,
    ImageResponsive,
    Icons,
    Link,
    Swiper,
    SwiperSlide,
    Loading,
    CardOne,
    CardNavbar,
    BillboardMobile,
  },
  data() {
    return {
      hover: { status: false, slug: "", menuType: "" },
      showSearch: false,
      strip: false,
      searchData: "",
      openSearch: false,
      screenWidth: null,
      showAutocomplete: false,
      canonical: null,
      scriptSeo: [],
      menusAccounts: [
        {
          name: "PENGATURAN",
          slug: "/account",
        },
        // {
        //   name: "KELAS SAYA",
        //   slug: "/account/kelas-saya",
        // },
        // {
        //   name: "EVENT SAYA",
        //   slug: "/account/event-saya",
        // },
      ],
      khasNarasi: [
        {
          name: "EVENT",
          slug: "event",
        },
        {
          name: "ACADEMY",
          slug: "academy",
        },
      ],
      menus: [
        // {
        //   name: "RAMADAN",
        //   slug: "religi",
        //   launch: true,
        //   icon: "ramadan",
        //   onHover: false,
        //   withHover: false,
        //   isCategory: false
        // },
        // {
        //   name: "#SAATNYABAIKAN",
        //   slug: "saatnyabaikan",
        //   launch: true,
        //   icon: "",
        //   onHover: false,
        //   withHover: false,
        //   isCategory: false
        // },
        {
          name: "EVENT",
          slug: "event",
          launch: true,
          icon: "",
          onHover: false,
          withHover: false,
          isCategory: false,
        },
        {
          name: "ACADEMY",
          slug: "academy",
          launch: true,
          icon: "",
          onHover: false,
          withHover: false,
          isCategory: false,
        },
      ],
      swiperOptions: {
        trendings: {
          pagination: false,
          autoHeight: true,
          calculateHeight: true,
          autoplay: {
            delay: 5000,
          },
          navigation: {
            nextEl: ".next-trending",
            prevEl: ".prev-trending",
          },
          // mousewheel: {
          //   releaseOnEdges: true,
          // },
          loop: true,
          breakpoints: {
            320: {
              slidesPerView: 1,
              pagination: true,
            },
            1023: {
              slidesPerView: "auto",
            },
          },
        },
      },
      brandChild: {
        active: false,
        data: [],
      },
      activeIndexProgram: null,
    };
  },
  watch: {
    hover: {
      handler(val) {
        if (val.status && val.slug) {
          if (!this.$store.state.navbar.articles_navbar.items[val.slug]) {
            this.$store.dispatch("navbar/getArticleNavbar", val.slug);
          }
        }
      },
      deep: true,
    },
    "$route.query.q": {
      handler: function (name) {
        if (name) {
          this.openSearch = false;
          this.searchData = name;
        } else {
          this.searchData = "";
        }
      },
      deep: true,
      immediate: true,
    },
    $route(to, from) {
      this.openSearch = false;
      this.showAutocomplete = false;
    },
  },
  computed: {
    ...mapGetters({ headReturn: "seo/headReturn" }),
    ...mapState({
      menus_with_hover: (state) => {
        return state.navbar.menus;
      },
      highlights: (state) => {
        return state.navbar.highlights;
      },
      programs: (state) => {
        return state.navbar.programs;
      },
    }),
  },
  async fetch() {
    await this.$store.dispatch("navbar/getMenus");
  },
  methods: {
    async initData() {
      this.$store.dispatch("navbar/getHighlights");
      this.$store.dispatch("navbar/getPrograms");
      this.$store.dispatch("navbar/getLeftCategory");
      this.$store.dispatch("navbar/getArticleProgram");
    },
    setActive(index) {
      this.brandChild.data = this.programs.items[index];
      if (this.activeIndexProgram == index) {
        this.activeIndexProgram = null;
      } else {
        this.activeIndexProgram = index;
      }
      if (this.activeIndexProgram != null) {
        this.brandChild.active = true;
      } else {
        this.brandChild.active = false;
      }
    },
    checkLogin() {
      if (this.$store.state.auth.loggedIn) {
        this.logout();
      } else {
        this.$router.push({
          name: "auth-login",
          query: {
            redirect: this.$route.path,
          },
        });
      }
    },
    expandSearch() {
      this.showSearch = true;
      setTimeout(() => {
        this.$refs.searchInput.focus();
      }, 100);
    },
    redirect() {
      this.resetSearch();
      this.showAutocomplete = false;
    },
    hide(event) {
      this.resetSearch();
      this.showAutocomplete = false;
    },
    resetSearch() {
      this.$store.commit("search/SET_AUTOCOMPLETE", []);
    },
    onSearch(e) {
      this.$store.dispatch("search/autocomplete", e).then(() => {
        this.showAutocomplete = true;
      });
    },
    goSearch() {
      if (this.searchData) {
        this.$router.push({
          name: "search",
          query: {
            q: searchData,
          },
        });
      } else {
        alert("Masukkan kata kunci");
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    getFullYear() {
      const date = new Date();
      return date.getFullYear();
    },
    logout() {
      if (this.$store.state.auth.user.provider !== "local") {
        this.$fire.auth.signOut();
        this.$auth.logout();
      } else {
        this.$auth.logout();
      }
      this.openSearch = false;
      this.showAutocomplete = false;
    },
    checkUrl(url) {
      if (url.includes("?")) {
        return url + "&ref=highlight";
      } else {
        return url + "?ref=highlight";
      }
    },
    slugify(str) {
      return str
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, "")
        .replace(/[\s_-]+/g, "-")
        .replace(/^-+|-+$/g, "");
    },
  },
  mounted() {
    if (this.$store.state.storeUser.session === null) {
      this.$store.commit("storeUser/setSession", Date.now());
    }
    this.canonical = window.location.href;
    this.screenWidth = window.innerWidth;
    if (this.screenWidth > 1000) {
      this.openSearch = false;
    }
    this.initData();
  },
};
</script>

<style lang="scss" scoped>
.swiper-pagination-bullets {
  display: none;
}
.slide-fade-enter-active {
  transition: all 0.1s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}
.navigation-wrapper {
  position: relative;
  z-index: 1000;
  // height: 80px;
  .side-bar-mobile {
    width: 100vw;
    height: 100vh;
    top: 0;
    right: -100vw;
    overflow: hidden;
    background-color: #ffffff;
    position: fixed;
    z-index: 9999;
    opacity: 0;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    &.enter {
      opacity: 1;
      right: 0;
    }
    .side-logo-and-close {
      padding: 16px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #929292;
      a {
        img {
          width: 100px;
          height: auto;
        }
      }
    }

    .side-content {
      position: relative;
      overflow-y: scroll;
      height: 100%;
      .side-search-wrapper {
        form {
          position: relative;
          margin-top: 16px;
          .search {
            position: relative;
            z-index: 1001;
            padding: 10px 10px 10px 40px;
            background-color: #8585854d;
            height: 34px;
            width: 100%;
            border-radius: 5px;
            box-shadow: none !important;
            outline: none !important;
            color: #858585;
            font-size: 16px;
            border: unset;
            &::-webkit-search-cancel-button {
              -webkit-appearance: none;
              height: 20px;
              width: 20px;
              border-radius: 10px;
              background: url("../assets/img/x-circle.svg") no-repeat 50% 50%;
              filter: invert(8%) sepia(100%) saturate(6481%) hue-rotate(246deg)
                brightness(102%) contrast(0%);
            }

            &::placeholder {
              /* Chrome, Firefox, Opera, Safari 10.1+ */
              color: #9e9e9e;
              opacity: 1; /* Firefox */
            }

            &:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: #9e9e9e;
            }

            &::-ms-input-placeholder {
              /* Microsoft Edge */
              color: #9e9e9e;
            }
            &.active {
              border-radius: 5px 5px 0 0;
              background: #5e5e5e !important;
            }

            &:focus {
              padding: 10px 40px 10px 10px;
            }
          }
          .search-icon {
            position: absolute;
            top: 50%;
            left: 6%;
            transform: translate(-50%, -50%);
            z-index: 1001;
            transition: all 0.5s;
            &.active {
              left: 94%;
            }
            svg {
              @media only screen and (max-width: 1024px) {
                height: 17px;
                width: auto;
              }
            }
          }
          .autocomplete-wrapper {
            position: relative;
            width: 100%;
            .autocomplete-search {
              position: absolute;
              z-index: 1001;
              top: 0;
              width: 100%;
              height: fit-content;
              max-height: 500px;
              background: #5e5e5e !important;
              overflow: auto;
              border-radius: 0px 0px 5px 5px;
              padding: 10px 0;
              opacity: 0;
              &.show {
                opacity: 1;
              }
              ul.list-unstyled {
                margin: 0;
                li {
                  cursor: pointer;
                  padding: 0px 10px;
                  &:hover {
                    background: #4a25aa;
                    .text {
                      border-color: transparent;
                      p {
                        color: #fafafa !important;
                      }
                    }
                  }
                  &:last-child {
                    .text {
                      border: none;
                    }
                  }
                  .text {
                    padding: 8px 0;
                    border-bottom: 1px solid lightgray;
                    color: #ffffff;
                    display: block;
                    p {
                      font-size: 14px;
                      margin: 0;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      -webkit-line-clamp: 2;
                      display: -webkit-box;
                      -webkit-box-orient: vertical;
                      pointer-events: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .side-login-wrapper {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 16px;
        .img-user {
          width: 20px;
          height: 20px;
          object-fit: cover;
        }
        .text-login {
          font-size: 14px;
          color: #0c0b0d;
        }
      }
      .side-trending {
        margin-top: 24px;
        .title {
          font-size: 12px;
          font-weight: 500;
          color: #4a25aa;
        }
        .list-trending {
          margin-bottom: 24px;
          .trending-item {
            display: block;
            font-size: 14px;
            color: #0c0b0d;
            padding: 10px 0;
            border-bottom: 1px solid #494949;
            &:hover {
              color: #0c0b0d !important;
            }
          }
        }
      }

      .side-program {
        .btn-wrapper {
          color: #858585;
          display: flex;
          flex-direction: row;
          overflow: scroll;
          gap: 12px;
          position: relative;
          margin-bottom: 16px;
          .btn-program {
            font-size: 12px;
            color: #858585;
            background: #8585854d;
            padding: 4px 10px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            gap: 10px;
            white-space: nowrap;
            &.show {
              font-weight: 500;
              color: #34128c;
              background: #ffe900;
              svg {
                transform: rotate(180deg);
              }
            }
          }
        }
        .child-program {
          width: 100%;
          max-height: 0;
          overflow: hidden;
          transition: all 0.5s;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: 1fr;
          grid-column-gap: 10px;
          grid-row-gap: 10px;
          .child {
            color: #858585;
            display: flex;
            align-items: center;
            gap: 8px;
            &:hover {
              color: #858585 !important;
            }
            svg {
              width: 20px;
              height: 20px;
              object-fit: cover;
            }
            .img-program {
              width: 20px;
              height: 20px;
              object-fit: cover;
              border-radius: 50rem;
              overflow: hidden;
            }
          }
          &.show {
            max-height: 500px;
          }
        }
      }

      .side-khas-narasi {
        margin-top: 24px;
        .title {
          font-size: 12px;
          font-weight: 500;
          color: #4a25aa;
          margin-bottom: 24px;
        }
        .list-khas-narasi {
          margin-bottom: 24px;
          .khas-narasi-item {
            display: block;
            font-size: 14px;
            font-weight: 500;
            color: #0c0b0d;
            padding: 10px 0;
            border-bottom: 1px solid #494949;
            &:hover {
              color: #0c0b0d !important;
            }
          }
        }
      }
      .side-social-media {
        display: flex;
        align-items: center;
        gap: 35px;
        padding: 15vh 0;
        justify-content: center;
      }
    }
  }
  .narasi-navbar {
    width: 100%;
    top: 0;
    z-index: 1000;
    position: fixed;
    .strip-text {
      background: #ffe900;
      .container {
        height: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .text {
          margin: 0;
          font-size: 12pt;
          font-weight: 500;
          text-align: center;
          @media only screen and (max-width: 1024px) {
            font-size: 10pt;
          }
        }
        svg {
          width: 18px;
          height: fit-content;
          cursor: pointer;
          @media only screen and (max-width: 1024px) {
            width: 14px;
          }
        }
      }
    }
    .navbar-top {
      border-bottom: 1px solid #e7e7e7;
      background-color: #ffffff;
      height: 58px;
      @media only screen and (max-width: 1024px) {
        height: 56px;
      }
      &.bgPrimary {
        @media only screen and (max-width: 1024px) {
          background: #4a25aa;
        }
      }
      .container {
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: space-between;
        &.top-desktop {
          display: flex;
          @media only screen and (max-width: 1024px) {
            display: none;
          }
        }
        &.top-mobile {
          display: none;
          @media only screen and (max-width: 1024px) {
            display: flex;
          }
        }
        &.around {
          @media only screen and (max-width: 1024px) {
            justify-content: space-around;
          }
        }
        .logo-wrapper {
          flex: 1;
          @media only screen and (max-width: 1024px) {
            height: 24px;
            width: auto;
          }
          .logo {
            img {
              width: 100px;
              height: auto;
            }
            @media only screen and (max-width: 1024px) {
              height: 100%;
              width: auto;
              svg {
                height: 100%;
                width: auto;
              }
            }
          }
        }

        .list-menu {
          flex: 3;
          height: 100%;
          display: flex;
          gap: 16px;
          align-items: center;
          justify-content: center;
          margin: 0;
          white-space: nowrap;
          -ms-overflow-style: none;
          scrollbar-width: none;
          @media only screen and (max-width: 1024px) {
            overflow-x: scroll;
            gap: 48px;
          }
          &::-webkit-scrollbar {
            display: none;
          }

          li {
            list-style: none;
            height: 100%;
            display: flex;
            align-items: center;
            position: static;
            .menu-item {
              font-size: 14px;
              font-weight: 700;
              color: #0c0b0d !important;
              display: flex;
              align-items: center;
              height: fit-content;
              border-bottom: 4px solid transparent;
              padding-top: 4px;
              @media only screen and (max-width: 1024px) {
                margin-right: 40px;
              }
              svg {
                width: 13px;
                height: auto;
                margin-right: 4px;
              }
              &:last-child {
                @media only screen and (max-width: 1024px) {
                  margin-right: 0;
                }
              }
              &.Link-exact-active {
                // border-bottom: 4px solid #ffe900;
                border-bottom: 4px solid #ffe900;
                opacity: 1;
              }
              &:hover {
                // color: #fafafa !important;
                // opacity: 0.75;
                border-bottom: 4px solid #ffe900;
                opacity: 1;
              }
              &.hover {
                // color: #fafafa !important;
                // opacity: 0.75;
                border-bottom: 4px solid #ffe900;
                opacity: 1;
              }
              &.soon {
                pointer-events: none;
                opacity: 1;
                color: #9f8cd0 !important;
                sup {
                  font-size: 8px;
                  font-weight: 700;
                  color: #fafafa;
                  padding: 6px 4px;
                  background: #8166c8;
                  border-radius: 3px;
                  margin-left: 4px;
                }
              }
              span {
                font-size: 20px;
                color: #ffe900;
                margin-right: 5px;
              }
            }
          }
        }
        .search-login-wrapper {
          display: flex;
          align-items: center;
          flex: 2;
          justify-content: flex-end;
          .search-wrapper {
            position: relative;
            margin-right: 30px;
            transition: ease-in-out;
            transition-duration: 0.5s;
            @media only screen and (max-width: 1024px) {
              margin-right: 0;
              margin-left: 20px;
            }

            svg {
              cursor: pointer;
              position: absolute;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
              z-index: 1;
              &.close {
                right: 1.5vw;
              }
            }
            .close-search {
              @media only screen and (max-width: 1024px) {
                position: absolute;
                right: 5px;
                top: 50%;
                transform: translateY(-50%);
                width: 20px;
                height: 20px;
              }
            }
            form {
              opacity: 0;
              &.show {
                opacity: 1;
              }
            }
            .search {
              padding: 10px 60px 10px 10px;
              background-color: #858585;
              height: 34px;
              border-radius: 5px;
              box-shadow: none !important;
              outline: none !important;
              color: #ffffff;
              border: unset;
              @media only screen and (max-width: 1024px) {
              }
              &.disabled {
                pointer-events: none;
              }
              &.custInput {
                @media only screen and (max-width: 1024px) {
                  width: 90%;
                }
                &.active {
                  border-radius: 5px 5px 0 0;
                }
              }
              &::-webkit-search-cancel-button {
                -webkit-appearance: none;
                height: 20px;
                width: 20px;
                border-radius: 10px;
                background: url("../assets/img/x-circle.svg") no-repeat 50% 50%;
                filter: invert(8%) sepia(100%) saturate(6481%)
                  hue-rotate(246deg) brightness(102%) contrast(0%);
              }

              &::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: #9e9e9e;
                opacity: 1; /* Firefox */
              }

              &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: #9e9e9e;
              }

              &::-ms-input-placeholder {
                /* Microsoft Edge */
                color: #9e9e9e;
              }
              &.active {
                border-radius: 5px 5px 0 0;
              }
            }
            .autocomplete-wrapper {
              position: relative;
              width: 100%;
              @media only screen and (max-width: 1024px) {
                width: 90%;
              }
              .autocomplete-search {
                position: absolute;
                z-index: 1002;
                top: 0;
                width: 100%;
                height: fit-content;
                max-height: 500px;
                background: #858585 !important;
                overflow: auto;
                border-radius: 0px 0px 5px 5px;
                padding: 10px 0;
                opacity: 0;
                &.show {
                  opacity: 1;
                }
                ul.list-unstyled {
                  margin: 0;
                  li {
                    cursor: pointer;
                    padding: 0px 10px;
                    &:hover {
                      background: #4a25aa;
                      .text {
                        border-color: transparent;
                        p {
                          color: #fafafa !important;
                        }
                      }
                    }
                    &:last-child {
                      .text {
                        border: none;
                      }
                    }
                    .text {
                      padding: 8px 0;
                      border-bottom: 1px solid lightgray;
                      color: #ffffff;
                      display: block;
                      p {
                        font-size: 14px;
                        margin: 0;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 2;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                      }
                    }
                  }
                }
              }
            }

            .btn-wrapper {
              position: absolute;
              right: 10px;
              top: 50%;
              transform: translateY(-50%);
              display: flex;
              justify-content: space-between;
              gap: 8px;
              &::before {
                content: "";
                border: 0.5px solid #b2b1b1;
                opacity: 0.5;
                align-self: stretch;
              }
              a {
                order: -1;
              }
              .search-icon {
                @media only screen and (max-width: 1024px) {
                  height: 17px;
                  width: auto;
                }
                &.close {
                  cursor: pointer;
                }
                &.custIcon {
                  @media only screen and (max-width: 1024px) {
                    position: unset;
                    transform: unset;
                  }
                }
              }
            }
          }
          .login-wrapper {
            @media only screen and (max-width: 1024px) {
              margin-right: 16px;
            }
            .profile {
              width: 24px;
              height: 24px;
              padding: 2px 5px;
            }
            .text-login {
              display: flex;
              align-items: center;
              gap: 8px;
              font-size: 0.7vw;
              color: #858585;
              @media only screen and (max-width: 1024px) {
                font-size: 14px;
              }

              &:hover {
                color: #858585 !important;
              }
            }
          }
        }
      }
    }
    .navbar-bottom {
      background: #ffffff;
      border-bottom: 1px solid #e7e7e7;
      height: 44px;
      @media only screen and (max-width: 1024px) {
        height: 46px;
      }
      .container {
        height: 100%;
        display: flex;
        align-items: center;
        overflow-x: scroll;
        white-space: nowrap;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
        .menu-item {
          font-size: 16px;
          font-weight: 700;
          color: #0c0b0d !important;
          display: flex;
          align-items: center;
          margin-right: 48px;
          height: 100%;
          border-bottom: 4px solid transparent;
          padding-top: 4px;
          @media only screen and (max-width: 1024px) {
            margin-right: 24px;
            font-size: 14px;
          }
          svg {
            width: 13px;
            height: auto;
            margin-right: 4px;
          }
          &:last-child {
            @media only screen and (max-width: 1024px) {
              margin-right: 0;
            }
          }
          &.Link-exact-active {
            border-bottom: 4px solid #ffe900;
            opacity: 1;
          }
          &:hover {
            border-bottom: 4px solid #ffe900;
            opacity: 1;
            // color: #fafafa !important;
            // opacity: 0.75;
          }
          &.soon {
            pointer-events: none;
            opacity: 1;
            color: #9f8cd0 !important;
            sup {
              font-size: 8px;
              font-weight: 700;
              color: #fafafa;
              padding: 6px 4px;
              background: #8166c8;
              border-radius: 3px;
              margin-left: 4px;
            }
          }
          span {
            font-size: 20px;
            color: #ffe900;
            margin-right: 5px;
          }
        }
      }
    }
    .navbar-trending {
      background: #ffffff;
      height: 38px;
      @media only screen and (max-width: 1024px) {
        height: 36px;
      }
      .container {
        height: 100%;
        display: flex;
        gap: 24px;
        align-items: center;
        overflow-x: auto;
        white-space: nowrap;
        -ms-overflow-style: none;
        scrollbar-width: none;
        @media only screen and (max-width: 1024px) {
          gap: 20px;
        }
        &::-webkit-scrollbar {
          display: none;
        }

        .slider-trending {
          @media only screen and (max-width: 1024px) {
            position: relative;
            overflow: hidden;
            display: flex;
            flex: 1;
          }
          .swiper-wrapper {
            display: flex;
            gap: 30px;
            @media only screen and (max-width: 1024px) {
              gap: 0;
            }
          }

          .arrows {
            display: flex;
            align-items: center;
            @media only screen and (max-width: 1024px) {
              display: none;
            }
            &::before {
              content: "";
              border: 1px solid #0c0b0d;
              opacity: 0.5;
              align-self: stretch;
              margin: 8px 4px;
            }
            .prev-trending {
              @media only screen and (max-width: 1024px) {
                order: -1;
              }
            }
            svg {
              @media only screen and (max-width: 1024px) {
                width: 24px;
                height: auto;
              }
            }
          }
          .swiper-container {
            margin: 0;
            @media only screen and (max-width: 1024px) {
              flex: 1;
            }
            .swiper-wrapper {
              .swiper-slide-duplicate {
                @media only screen and (min-width: 1023px) {
                  display: none;
                }
              }
            }
          }
          .trending-item {
            font-size: 12px;
            color: #0c0b0d !important;
            overflow: hidden;
            display: flex;
            align-items: center;
            @media only screen and (min-width: 1000px) {
              width: fit-content !important;
            }
          }
        }
      }
    }
  }
  .shadow-search {
    background: #0c0b0d;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
  }
}

.content {
  margin-top: 102px;
}
</style>
