var render = function render(){var _vm=this,_c=_vm._self._c;return (
    this.$route.query.comment !== 'show' && !this.$route.name.includes('auth')
  )?_c('footer',{staticClass:"footer",class:{
    custMargin:
      this.$route.name == 'event-organizer-slug' ||
      this.$route.name == 'event-organizer-slug-survey',
  }},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"footer-content"},[_c('div',{staticClass:"footer-detail"},[_c('div',{staticClass:"logo-wrapper"},[_c('Icons',{attrs:{"name":"logo-narasi"}})],1),_vm._v(" "),_c('div',{staticClass:"footer-list-menu is-desktop"},[_c('Link',{attrs:{"to":{
              name: 'about-us',
              query: {
                menu: 'informasi-perusahaan',
              },
            }}},[_vm._v("Informasi Perusahaan"),_c('span',[_vm._v("•")])]),_vm._v(" "),_c('Link',{attrs:{"to":{
              name: 'about-us',
              query: {
                menu: 'syarat-dan-ketentuan-umum',
              },
            }}},[_vm._v("Syarat dan Ketentuan Umum")]),_c('br'),_vm._v(" "),_c('Link',{attrs:{"to":{
              name: 'about-us',
              query: {
                menu: 'privasi',
              },
            }}},[_vm._v("Privasi"),_c('span',[_vm._v("•")])]),_vm._v(" "),_c('Link',{attrs:{"to":{
              name: 'about-us',
              query: {
                menu: 'pedoman-media-siber',
              },
            }}},[_vm._v("Pedoman Liputan Media Siber"),_c('span',[_vm._v("•")])]),_vm._v(" "),_c('Link',{attrs:{"to":{
              name: 'about-us',
              query: {
                menu: 'kontak-kami',
              },
            }}},[_vm._v("Kontak Kami"),_c('span',[_vm._v("•")])]),_vm._v(" "),_c('Link',{attrs:{"to":{
              name: 'karir',
            }}},[_vm._v("Karir")])],1),_vm._v(" "),_c('div',{staticClass:"footer-social-media"},[_c('a',{attrs:{"href":"https://www.instagram.com/narasi.tv","target":"_blank","rel":"noopener"}},[_c('Icons',{attrs:{"name":"logo-instagram-new"}})],1),_vm._v(" "),_c('a',{attrs:{"href":"https://twitter.com/narasitv","target":"_blank","rel":"noopener"}},[_c('Icons',{attrs:{"name":"logo-x"}})],1),_vm._v(" "),_c('a',{attrs:{"href":"https://www.facebook.com/narasi.tv","target":"_blank","rel":"noopener"}},[_c('Icons',{attrs:{"name":"logo-facebook"}})],1),_vm._v(" "),_c('a',{attrs:{"href":"https://www.youtube.com/channel/UCzI8ArgVBHXN3lSz-dI0yRw","aria-label":"Youtube","target":"_blank","rel":"noopener"}},[_c('Icons',{attrs:{"name":"logo-youtube"}})],1)])]),_vm._v(" "),_c('div',{staticClass:"footer-list-menu is-mobile"},[_c('Link',{attrs:{"to":{
            name: 'about-us',
            query: {
              menu: 'informasi-perusahaan',
            },
          }}},[_vm._v("Informasi Perusahaan")]),_c('br'),_vm._v(" "),_c('Link',{attrs:{"to":{
            name: 'about-us',
            query: {
              menu: 'syarat-dan-ketentuan-umum',
            },
          }}},[_vm._v("Syarat dan Ketentuan Umum"),_c('span',[_vm._v("•")])]),_vm._v(" "),_c('Link',{attrs:{"to":{
            name: 'about-us',
            query: {
              menu: 'privasi',
            },
          }}},[_vm._v("Privasi")]),_c('br'),_vm._v(" "),_c('Link',{attrs:{"to":{
            name: 'about-us',
            query: {
              menu: 'pedoman-media-siber',
            },
          }}},[_vm._v("Pedoman Liputan Media Siber"),_c('span',[_vm._v("•")])]),_vm._v(" "),_c('Link',{attrs:{"to":{
            name: 'about-us',
            query: {
              menu: 'kontak-kami',
            },
          }}},[_vm._v("Kontak Kami"),_c('span',[_vm._v("•")])]),_vm._v(" "),_c('Link',{attrs:{"to":{
            name: 'karir',
          }}},[_vm._v("Karir")])],1),_vm._v(" "),_c('div',{staticClass:"footer-partner"},[_c('p',{staticClass:"footer-partner-text"},[_vm._v("Our Partner")]),_vm._v(" "),_c('div',{staticClass:"footer-partner-wrapper"},[_c('ImageResponsive',{attrs:{"imageUrl":"partner/logo-gdp-new.webp"}}),_vm._v(" "),_c('ImageResponsive',{attrs:{"imageUrl":"partner/logo-argor-new.webp"}})],1)])])]),_vm._v(" "),_c('div',{staticClass:"footer-copyright"},[_c('p',{staticClass:"footer-copyright-text"},[_vm._v("\n      © "+_vm._s(new Date().getFullYear())+" Narasi TV | All rights reserved.\n    ")])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }