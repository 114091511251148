<template>
  <footer
    class="footer"
    :class="{
      custMargin:
        this.$route.name == 'event-organizer-slug' ||
        this.$route.name == 'event-organizer-slug-survey',
    }"
    v-if="
      this.$route.query.comment !== 'show' && !this.$route.name.includes('auth')
    "
  >
    <div class="container">
      <div class="footer-content">
        <div class="footer-detail">
          <div class="logo-wrapper">
            <Icons name="logo-narasi" />
          </div>
          <div class="footer-list-menu is-desktop">
            <!-- <Link
              :to="{
                name: 'about-us',
                query: {
                  menu: 'press-release',
                },
              }"
              >Press Release<span>&bull;</span></Link
            > -->
            <Link
              :to="{
                name: 'about-us',
                query: {
                  menu: 'informasi-perusahaan',
                },
              }"
              >Informasi Perusahaan<span>&bull;</span></Link
            >
            <Link
              :to="{
                name: 'about-us',
                query: {
                  menu: 'syarat-dan-ketentuan-umum',
                },
              }"
              >Syarat dan Ketentuan Umum</Link
            ><br />
            <Link
              :to="{
                name: 'about-us',
                query: {
                  menu: 'privasi',
                },
              }"
              >Privasi<span>&bull;</span></Link
            >
            <Link
              :to="{
                name: 'about-us',
                query: {
                  menu: 'pedoman-media-siber',
                },
              }"
              >Pedoman Liputan Media Siber<span>&bull;</span></Link
            >
            <Link
              :to="{
                name: 'about-us',
                query: {
                  menu: 'kontak-kami',
                },
              }"
              >Kontak Kami<span>&bull;</span></Link
            >
            <Link
              :to="{
                name: 'karir',
              }"
              >Karir</Link
            >
          </div>
          <div class="footer-social-media">
            <a
              href="https://www.instagram.com/narasi.tv"
              target="_blank"
              rel="noopener"
              ><Icons name="logo-instagram-new"
            /></a>
            <a
              href="https://twitter.com/narasitv"
              target="_blank"
              rel="noopener"
              ><Icons name="logo-x"
            /></a>
            <a
              href="https://www.facebook.com/narasi.tv"
              target="_blank"
              rel="noopener"
              ><Icons name="logo-facebook"
            /></a>
            <a
              href="https://www.youtube.com/channel/UCzI8ArgVBHXN3lSz-dI0yRw"
              aria-label="Youtube"
              target="_blank"
              rel="noopener"
              ><Icons name="logo-youtube"
            /></a>
          </div>
        </div>
        <div class="footer-list-menu is-mobile">
          <!-- <Link
            :to="{
              name: 'about-us',
              query: {
                menu: 'press-release',
              },
            }"
            >Press Release<span>&bull;</span></Link
          > -->
          <Link
            :to="{
              name: 'about-us',
              query: {
                menu: 'informasi-perusahaan',
              },
            }"
            >Informasi Perusahaan</Link
          ><br />
          <Link
            :to="{
              name: 'about-us',
              query: {
                menu: 'syarat-dan-ketentuan-umum',
              },
            }"
            >Syarat dan Ketentuan Umum<span>&bull;</span></Link
          >
          <Link
            :to="{
              name: 'about-us',
              query: {
                menu: 'privasi',
              },
            }"
            >Privasi</Link
          ><br />
          <Link
            :to="{
              name: 'about-us',
              query: {
                menu: 'pedoman-media-siber',
              },
            }"
            >Pedoman Liputan Media Siber<span>&bull;</span></Link
          >
          <Link
            :to="{
              name: 'about-us',
              query: {
                menu: 'kontak-kami',
              },
            }"
            >Kontak Kami<span>&bull;</span></Link
          >
          <Link
            :to="{
              name: 'karir',
            }"
            >Karir</Link
          >
        </div>
        <div class="footer-partner">
          <p class="footer-partner-text">Our Partner</p>
          <div class="footer-partner-wrapper">
            <ImageResponsive imageUrl="partner/logo-gdp-new.webp" />
            <ImageResponsive imageUrl="partner/logo-argor-new.webp" />
            <!-- <ImageResponsive imageUrl="partner/logo-xl-home.png" /> -->
          </div>
        </div>
      </div>
    </div>
    <div class="footer-copyright">
      <p class="footer-copyright-text">
        &copy; {{ new Date().getFullYear() }} Narasi TV | All rights reserved.
      </p>
    </div>
  </footer>
</template>

<script>
import ImageResponsive from "@/components/ImageResponsive";
import Link from "@/components/Link";
import Icons from "@/components/Icons";

export default {
  components: {
    ImageResponsive,
    Link,
    Icons,
  },
};
</script>

<style lang="scss" scoped>
.footer {
  border-top: 1px solid lightgray;
  &.custMargin {
    @media only screen and (max-width: 1024px) {
      margin-bottom: 80px !important;
    }
  }
  .container {
    .footer-content {
      padding: 50px 0;
      @media only screen and (max-width: 1024px) {
        padding: 30px 0;
      }
      .footer-detail {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        align-items: center;
        @media only screen and (max-width: 1024px) {
          margin-bottom: 20px;
        }
        .logo-wrapper {
          width: 25%;
          @media only screen and (max-width: 1024px) {
            width: 50%;
          }
          svg {
            @media only screen and (max-width: 1024px) {
              width: 82px;
              height: auto;
            }
          }
        }
        // FOOTER LIST MENU DESKTOP
        .footer-list-menu {
          width: 50%;
          text-align: center;
          a {
            font-size: 14px;
            font-weight: 400;
            color: #757575 !important;
          }
          span {
            font-size: 14px;
            padding: 0 10px;
          }
        }
        // FOOTER LIST MENU DESKTOP END
        .footer-social-media {
          width: 25%;
          text-align: right;
          @media only screen and (max-width: 1024px) {
            width: 50%;
          }
          a {
            svg {
              width: 31px;
              height: 31px;
              margin: 0 10px;
              @media only screen and (max-width: 1024px) {
                width: 20px;
                height: 20px;
                margin: 0 2px;
              }
            }
          }
        }
      }
      // FOOTER LIST MENU MOBILE
      .footer-list-menu {
        width: 50%;
        text-align: center;
        @media only screen and (max-width: 1024px) {
          width: 70%;
          margin: 0 auto 30px;
        }
        a {
          font-size: 14px;
          font-weight: 400;
          color: #757575 !important;
          @media only screen and (max-width: 1024px) {
            font-size: 10px;
          }
        }
        span {
          font-size: 14px;
          padding: 0 10px;
          @media only screen and (max-width: 1024px) {
            font-size: 10px;
            padding: 0 5px;
          }
        }
      }
      // FOOTER LIST MENU MOBILE END
      .footer-partner {
        text-align: center;
        .footer-partner-text {
          font-size: 10px;
          font-weight: 400;
          color: #9e9e9e;
          margin-bottom: 5px;
        }
        .footer-partner-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          .image {
            margin: 0 10px;
            width: auto;
            ::v-deep img {
              height: 35px;
              width: auto;
            }
          }
        }
      }
    }
  }
  .footer-copyright {
    background: #34128c;
    padding: 10px 0;
    .footer-copyright-text {
      font-size: 10px;
      font-weight: 500;
      color: white;
      text-align: center;
      margin: 0;
    }
  }
}
</style>
